import Router from "vue-router";

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: require("../fuwa-body/home").default,
    },
    {
      path: "/article/:id",
      name: "Article",
      component: require("../fuwa-body/article").default,
    },
    {
      path: "/article-list",
      name: "ArticleList",
      component: require("../fuwa-body/article-list").default,
    },
    {
      path: "/product",
      name: "Product",
      component: require("../fuwa-body/product").default,
    },
    {
      path: "/profile",
      name: "Profile",
      component: require("../fuwa-body/profile").default,
    },
    {
      path: "/deviation",
      name: "Deviation",
      component: require("../fuwa-body/tool/deviation").default,
    },
    {
      path: "/nazotoki",
      name: "Nazotoki",
      component: require("../fuwa-body/tool/nazotoki").default,
    },
    {
      path: "/nazotoki/run/:id",
      name: "NazotokiRun",
      component: require("../fuwa-body/tool/nazotoki-run").default,
    },
    // {
    //   path: "/privacy-policy/last-diary",
    //   name: "LastDiaryPrivacyPolicy",
    //   component: require("../fuwa-body/privacy-policy/last-diary").default,
    // },
    // {
    //   path: "/privacy-policy/pre-school-visit",
    //   name: "PreSchoolVisitPrivacyPolicy",
    //   component: require("../fuwa-body/privacy-policy/pre-school-visit")
    //     .default,
    // },
    {
      path: "/privacy-policy/shake-judge",
      name: "ShakeJudgePrivacyPolicy",
      component: require("../fuwa-body/privacy-policy/shake-judge").default,
    },
    {
      path: "/privacy-policy/want-notice",
      name: "WantNoticePrivacyPolicy",
      component: require("../fuwa-body/privacy-policy/want-notice").default,
    },
    {
      path: "/privacy-policy/simple-maze",
      name: "SimpleMazePrivacyPolicy",
      component: require("../fuwa-body/privacy-policy/simple-maze").default,
    },
    {
      path: "/faq/want-notice",
      name: "WantNoticeFaq",
      component: require("../fuwa-body/faq/want-notice").default,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
